<template>
  <div class="left-w f-ccc f12">
    <div @click="goPage(it)" class="h-m flex flex-column ac jc po rl menu-li" v-for="(it, index) in menu" :key="index">
      <div class="text-center mgb6 f28" :class="it.icon"></div>
      <div class="text-center">{{ it.name }}</div>
      <div v-if="it.children && it.children.length > 0" class="abl menu-right">
        <span v-for="(c, ci) in it.children" :key="ci">
          <div v-if="c.show" @click.stop="goPage(c)" class="f-title f-gray">
            {{ c.name }}
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: [],
      url: {
        getmenus: "/sysadminsystem/getmenus"
      }
    };
  },
  created() {
    this.loadMenu();
  },
  methods: {
    loadMenu: function () {
      this.$rq.postAction(this.url.getmenus, this.where).then((rs) => {
        this.menu = [];
        if (rs.code == 1) {
          this.menu = rs.list;
        }
      });
    },
    goPage: function (menu) {
      if (menu.router != "") {
        var t = new Date().getTime();
        this.$router.push({
          name: menu.router,
          query: {
            t: t,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.left-w {
  min-height: calc(100vh - 60px);
  width: 100px;
  background-color: #545c64;
}

.h-m {
  height: 60px;
  padding: 10px 0px;
}

.icon {
  border: 2px solid #ccc;
  border-radius: 100%;
  width: 28px;
  height: 28px;
}

.h-m:hover {
  background-color: #404a50;
}

.h-m:hover .menu-right {
  display: block;
}

.menu-right {
  top: 0px;
  left: 100px;
  width: 200px;
  max-height: 750px;
  background-color: #fff;
  box-shadow: 0 0 10px #ddd;
  display: none;
  z-index: 1000;
  overflow-y: auto;
}
.menu-right::-webkit-scrollbar {
  display: none;
}
.menu-li:last-child .menu-right,.menu-li:nth-last-child(4) .menu-right,.menu-li:nth-last-child(3) .menu-right,.menu-li:nth-last-child(2) .menu-right ,.menu-li:nth-last-child(1) .menu-right{
  top: auto;
  bottom: 0px;
}

.menu-right div {
  padding: 14px 20px;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}

.menu-right div:hover {
  outline: 0;
  background-color: #ecf5ff;
}
</style>